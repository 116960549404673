<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <v-overlay :value="overlay" v-if="overlay">
      <cz-spinner :size="60" color="white" class="flex-grow-1" />
    </v-overlay>
    <div class="pa-6">
      <on-boarding-summery
        v-if="vendor"
        :form="vendor"
        :primary-categories="primaryCategories"
        :secondary-categories="secondaryCategories"
      />
    </div>
    <v-footer fixed :height="80">
      <div class="d-flex justify-space-between full-width">
        <cz-button
          v-if="isPendingApproval"
          large
          color="success"
          :icon-src="mdiCheckAll"
          :title="$t('admin.approveVendor')"
          @click="onApprove"
        />

        <div class="d-flex" style="column-gap: 30px">
          <cz-button
            v-if="isPendingApproval || isActive"
            large
            color="primary"
            text
            :icon-src="mdiArrowLeftTop"
            :title="$t('admin.returnVendor')"
            @click="onReturn"
          />
          <cz-button
            v-if="isPendingApproval"
            large
            text
            color="negative"
            :icon-src="mdiCancel"
            :title="$t('admin.rejectVendor')"
            @click="onReject"
          />
        </div>
      </div>
    </v-footer>
    <cz-dialog
      v-if="reasonDialog && reasonDialog.show"
      v-model="reasonDialog.show"
      v-bind="reasonDialog"
      :persistent="reasonDialog.loading"
      @close="reasonDialog.show = false"
    >
      <v-card-text class="mt-4">
        <v-form :disabled="reasonDialog.loading">
          <cz-form-field :label="reasonDialog.inputLabel" required>
            <cz-input
              v-model="reasonDialog.reason"
              multi-line
              :rows="10"
              no-resize
            />
          </cz-form-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <cz-button
          large
          :min-width="200"
          color="primary"
          :title="reasonDialog.actionButtonTitle"
          :loading="reasonDialog.loading"
          :disabled="!reasonDialog.reason"
          @click="reasonDialog.actionHandler()"
        />
      </v-card-actions>
    </cz-dialog>
  </content-layout>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheckAll,
  mdiCancel,
  mdiArrowLeftTop,
  mdiSend
} from '@mdi/js';
import { getVendor, executeVendorDecision } from '@/core/api';
import {
  CzSpinner,
  CzInput,
  CzFormField,
  CzButton,
  CzDialog
} from '@/components';
import ContentLayout from '@/layouts/ContentLayout';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { format } from 'date-fns';
const initialReasonDialog = {
  show: false,
  title: '',
  inputLabel: '',
  decision: '',
  actionButtonTitle: '',
  width: 500,
  actionHandler: null,
  loading: false,
  reason: ''
};
export default {
  name: 'ManageVendorPage',
  components: {
    CzSpinner,
    CzButton,
    CzInput,
    ContentLayout,
    CzDialog,
    CzFormField,
    OnBoardingSummery: () =>
      import('@/domain/vendor/components/OnBoardingSummery')
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  created() {
    this.loadVendor();
  },
  computed: {
    ...mapFields('vendor', ['primaryCategories', 'secondaryCategories']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('admin.adminHomePage'),
          to: {
            name: 'super-admin-home'
          },
          disabled: false,
          exact: true
        },
        {
          text: this.$t('admin.manageVendors'),
          to: {
            name: 'manage-vendors'
          },
          disabled: false,
          exact: true
        },
        {
          text: this.vendor?.businessName || '...',
          disabled: true,
          exact: true
        }
      ];
    },
    isPendingApproval() {
      return (
        this.vendor?.status === 'pending_approval' ||
        this.vendor?.status === 'pending_docs_update'
      );
    },
    isActive() {
      return (
        this.vendor?.status === 'active' ||
        this.vendor?.status === 'pending_docs_update'
      );
    }
  },
  data() {
    return {
      vendor: null,
      mdiCancel,
      mdiSend,
      mdiArrowLeftTop,
      mdiCheckAll,
      mdiChevronLeft,
      loading: false,
      overlay: false,
      reasonDialog: cloneDeep(initialReasonDialog)
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage']),
    async loadVendor() {
      try {
        this.loading = true;
        const vendor = await getVendor(this.id, {
          $populate: ['files', 'categories']
        });

        if (vendor.accountingBooksCertificationExpirationDate) {
          vendor.accountingBooksCertificationExpirationDate = format(
            new Date(vendor.accountingBooksCertificationExpirationDate),
            'dd/MM/yyyy'
          );
        }

        this.vendor = vendor;
      } finally {
        this.loading = false;
      }
    },
    async onApprove() {
      try {
        this.overlay = true;
        await executeVendorDecision({
          vendorId: this.id,
          decision: 'active'
        });
        this.showSuccessToastMessage(this.$t('admin.vendorApprovedMessage'));
        this.$router.replace({
          name: 'manage-vendors'
        });
      } finally {
        this.overlay = false;
      }
    },
    async onReject() {
      this.reasonDialog.inputLabel = this.$t('admin.rejectDecisionMessage');
      this.reasonDialog.title = this.$t('admin.rejectDecisionTitle');
      this.reasonDialog.actionButtonTitle = this.$t('admin.rejectVendor');
      this.reasonDialog.actionHandler = async () => {
        try {
          this.reasonDialog.loading = true;
          await executeVendorDecision({
            vendorId: this.id,
            decision: 'blocked',
            reason: this.reasonDialog.reason
          });
          this.showSuccessToastMessage(this.$t('admin.vendorRejectedMessage'));
          this.reasonDialog.show = false;
          this.$router.replace({
            name: 'manage-vendors'
          });
        } finally {
          this.overlay = false;
          this.reasonDialog.loading = false;
        }
      };
      this.reasonDialog.show = true;
    },
    async onReturn() {
      this.reasonDialog.inputLabel = this.$t('admin.returnDecisionMessage');
      this.reasonDialog.title = this.$t('admin.returnDecisionTitle');
      this.reasonDialog.actionButtonTitle = this.$t('admin.returnVendor');
      this.reasonDialog.actionHandler = async () => {
        try {
          this.reasonDialog.loading = true;
          await executeVendorDecision({
            vendorId: this.id,
            decision: 'returned',
            reason: this.reasonDialog.reason
          });
          this.showSuccessToastMessage(this.$t('admin.vendorReturnedMessage'));
          this.reasonDialog.show = false;
          this.$router.replace({
            name: 'manage-vendors'
          });
        } finally {
          this.overlay = false;
          this.reasonDialog.loading = false;
        }
      };
      this.reasonDialog.show = true;
    }
  },
  watch: {
    'reasonDialog.show': {
      handler(value) {
        if (!value) {
          this.reasonDialog = cloneDeep(initialReasonDialog);
        }
      }
    }
  }
};
</script>

<style></style>
